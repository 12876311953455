import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { handleGetApis } from '../../../../Utilities/Utils';
import { handleFormatDateTime } from '../../../Services/Functions';

function LiveBus() {
  const [liveTime, setLiveTime] = useState(new Date())
  const customIcon = new L.Icon({
    iconUrl: require('../../../../Assets/maplogo2.png'),
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  const customIcon2 = new L.Icon({
    iconUrl: require('../../../../Assets/school-bus.png'),
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
  const [busLocations, setBusLocations] = useState([]);

  const handleBusLocations = async () => {
    try {
      const response = await handleGetApis('transitTrack/bus-loc');
      if (response && Array.isArray(response.data)) {
        console.log(response.data, "line 29")
        setBusLocations(response.data);
        console.log(response.data, "Bus locations loaded"); // Debugging output
      } else {
        console.error("Expected an array of locations, but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching bus locations:", error);
    }
  };

  useEffect(() => {
    handleBusLocations();
  }, []);

  return (
    <MapContainer center={[22.719568, 75.857727]} zoom={12} style={{ height: "70vh", width: "100%" }}>
      <TileLayer
        url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=cc6dadf1fc96479db163cb44ceece80c"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {busLocations.length > 0 && busLocations.map((item, idx) => (
        <Marker
          key={idx}
          position={[item.location._latitude, item.location._longitude]}
          icon={(liveTime - new Date(item?.time)) <= 30 * 60 * 1000
            ? customIcon2
            : customIcon}
        >
          <Popup>
            Time: {handleFormatDateTime(item.time)} <br />
            Route ID: {item.route_id} <br />
            Vehicle No: {item.vehicle_no}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default LiveBus;
