import React, { useEffect, useState } from 'react'
import AppNavbar from '../../Common/App_Navbar/AppNavbar'
import { handleGetApis, handlePostApis } from '../../../Utilities/Utils'
import LineChart from '../../Common/LineChart/LineChart'
import "./TransitEye.css";
import { formatDateTime } from '../../Services/Functions';
import LiveBus from './Live_Bus/LiveBus';
import dwn from "../../../Assets/save-button.jpg";
import LineChartNew from '../../Common/LineChart/LineChartNew';

function TransitEye() {
  const [data, setData] = useState(null);
  const [time, setTime] = useState(new Date());
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState({});
  const [timeScale, setTimeScale] = useState('d');
  const [dataType, setDataType] = useState('plain');
  const [chartData, setChartData] = useState()
  const [from, setFrom] = useState(null)
  const [analysisType, setAnalysisType] = useState(null)
  const [to, setTo] = useState(null)
  const [buses, setBuses] = useState([])
  const [selectedBus, setSelectedBus] = useState('All')
  const [routeID, setRouteID] = useState('')
  const [newDeviceData, setNewDeviceData] = useState({})
  const [newChartData, setNewChartData] = useState()
  const [busLocations, setBusLocations] = useState([])
  const [activeCount, setActiveCount] = useState()
  const [liveTime, setLiveTime] = useState(new Date())
  useEffect(() => {
    const response = handleGetApis("transit/list");
    response.then(function (result) {
      if (result) {
        setDevices(result?.collections);
      }
    });
  }, []);


  const handleBusLocations = async () => {
    try {
      const response = await handleGetApis('transitTrack/bus-loc');
      if (response && Array.isArray(response.data)) {
        console.log(response.data, "line 29")
        setBusLocations(response.data);
        console.log(response.data, "Bus locations loaded"); // Debugging output
      } else {
        console.error("Expected an array of locations, but got:", response.data);
      }
    } catch (error) {
      console.error("Error fetching bus locations:", error);
    }
  };
  
  useEffect(()=>{
    handleBusLocations()
  },[])


  useEffect(()=>{
    if (busLocations.length > 0){
      let activeCounts = 0;
      busLocations.map((item)=>{
        if ((liveTime - new Date(item?.time)) <= 30 * 60 * 1000){
          activeCounts++;
        }
      })
      setActiveCount(activeCounts);
      console.log(activeCounts)
    }
  },[busLocations])
    
  const handleDeviceInfo = (e) => {
    // console.log(e.target.value);
    setNewChartData()
    setDeviceData([])
    setBuses([])
    setSelectedBus("All")
    const response = handlePostApis("transit/route-stats", { collectionName: e.target.value });
    response.then(function (result) {
      console.log(result, "transit/route-stats")
      if (result) {
        setChartData(result);
        setDeviceData(result);
      }

    }).catch(function (error) {
      console.error("Error:", error);
    });
  };
  useEffect(() => {
    setChartData(null)
  }, [analysisType])

  useEffect(() => {
    let busNo = [];
    console.log(deviceData, "busno device data")
    if (deviceData) {
      deviceData?.data?.map((item) => {
        const bus = busNo.find((x) => x === item.vehicle_no);
        if (!bus) {
          busNo.push(item.vehicle_no);
        }
      })

    }
    // console.log(busNo, "busno")
    setBuses(busNo)
  }, [deviceData])

  useEffect(() => {
    // console.log(selectedBus)
    if (deviceData) {
      if (analysisType == "occupancy") {
        if (selectedBus === "All") {
          setNewDeviceData(deviceData)
          setNewChartData(chartData)
        } else {
          const fData = {
            totalEntryCount: 0,
            totalExitCount: 0,
            data: []
          };
          const filteredData = deviceData?.data?.filter((x) => x.vehicle_no === selectedBus);
          filteredData?.forEach((item) => {
            fData.totalEntryCount += item["total entry count"];
            fData.totalExitCount += item["total exit count"];
          });
          fData.data = filteredData;

          setNewDeviceData(fData);
          setNewChartData(fData);
          // console.log(fData, "Filtered Device Data");
        }
      } else if (analysisType == "aggregate") {
        if (selectedBus === "All") {
          let newData = calculateCumulativeSums(deviceData?.data);
          console.log(newData, "newData")
          setNewDeviceData(newData);
          setNewChartData(newData);
        } else {
          const filteredData = deviceData?.data?.filter((x) => x.vehicle_no === selectedBus);
          let newData = calculateCumulativeSums(filteredData);
          console.log(newData, "newData")
          setNewDeviceData(newData);
          setNewChartData(newData);
        }
      }
    }
  }, [selectedBus, deviceData, chartData])
  // useEffect(() => {
  //   // h d w m
  //   if (timeScale === 'h') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);
  //     now.setHours(now.getHours() - 6);
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'd') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setHours(now.getHours() - 24);
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'w') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setDate(now.getDate() - 7)
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  //   else if (timeScale === 'm') {
  //     const now = new Date();
  //     const currentTime = formatDateTime(now);
  //     setTo(currentTime);

  //     now.setDate(now.getMonth() - 1)
  //     const adjustedTime = formatDateTime(now);
  //     setFrom(adjustedTime);
  //   }
  // }, [timeScale])

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);


  function calculateCumulativeSums(data) {
    let cumulativeEntries = 0;
    let cumulativeExits = 0;
    console.log(data, "cumulative sums data")
    return data?.map(item => {
      // Clone the item and its data to avoid mutation
      let newItem = {};
      // Extract current entry and exit counts
      const entryCount = item['total entry count'];
      const exitCount = item['total exit count'];
      // Update cumulative counts
      if (entryCount != null) {
        cumulativeEntries += entryCount;
        newItem['total entry count'] = cumulativeEntries;
      } else {
        newItem['total entry count'] = undefined;
      }

      if (exitCount != null) {
        cumulativeExits += exitCount;
        newItem['total exit count'] = cumulativeExits;
      } else {
        newItem['total exit count'] = undefined;
      }
      // Calculate occupancy and add to the data
      const occupancy = cumulativeEntries - cumulativeExits;
      newItem['occupancy'] = occupancy;
      newItem['datetime'] = item.datetime;
      newItem.vehicle_no = item.vehicle_no;
      // Add the cumulative counts to the item
      newItem.totalEntryCount = cumulativeEntries;
      newItem.totalExitCount = cumulativeExits;
      return newItem;
    });
  }

  const handleDownloadData = () => {
    const csvRows = [];
    const headers = ["RouteID", ...Object.keys(chartData?.data[0])];
    csvRows.push(headers.join(','));

    chartData.data.forEach((row, index) => {
      const values = headers.map(header => {
        if (header === "RouteID") {
          return routeID;
        }
        const value = row[header];
        return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
      });
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${routeID}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <AppNavbar />
      <section className="mainContainer">
        <section className="dashboardContainer" style={{ position: "relative" }}>
          <h1>TransitEye Dashboard</h1>
          <select onChange={(e) => { setAnalysisType(e.target.value) }}>
            <option value="default" disabled selected>Select Analysis Type</option>
            <option value="occupancy">Occupancy Analysis</option>
            <option value="live-bus">Live Bus Analysis</option>
            <option value="aggregate">Aggregate Analysis</option>
          </select>
          {analysisType === "occupancy" && (
            <>
              <select style={{ marginInline: "35px" }} onChange={(e) => { { handleDeviceInfo(e) }; setRouteID(e.target.value) }}>
                <option value="default" disabled selected>Select a Route</option>
                {devices?.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              <select style={{ display: buses?.length > 1 ? '' : 'none' }} onChange={(e) => { setSelectedBus(e.target.value) }}>
                <option value={'All'}>All</option>
                {buses && buses?.length > 0 &&
                  buses?.map((item) => {
                    return (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    )
                  })
                }
              </select>
              {newDeviceData && newChartData && (
                <>
                  <div className="infoCont">
                    <div className="infoBox">
                      <h2>{newDeviceData?.totalEntryCount}</h2>
                      <p>Number of entries</p>
                    </div>
                    <div className="infoBox" style={{ border: "3px solid red" }}>
                      <h2>{newDeviceData?.totalExitCount}</h2>
                      <p>Number of exits</p>
                    </div>
                  </div>
                  <div className='linechart'>
                    <button style={{ display: newChartData && newChartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                    <LineChart chartData={newChartData} timeScale={timeScale} />
                  </div>
                </>
              )}
            </>
          )}
          {analysisType == "live-bus" && (
            <>
              <LiveBus />
              <div className='device-clock' style={{display: "flex", flexDirection: "column", top:"30%"}}>
                <strong >{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</strong>
                <strong >Total Devices: {busLocations.length}</strong>
                <strong >Online Devices: {activeCount}</strong>
              </div>
            </>
          )}

          {analysisType == "aggregate" && (
            <>
              <select style={{ marginInline: "35px" }} onChange={(e) => { { handleDeviceInfo(e) }; setRouteID(e.target.value) }}>
                <option value="default" disabled selected>Select a Route</option>
                {devices?.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
              <select style={{ display: buses?.length > 1 ? '' : 'none' }} onChange={(e) => { setSelectedBus(e.target.value) }}>
                <option value={'All'}>All</option>
                {buses && buses?.length > 0 &&
                  buses?.map((item) => {
                    return (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    )
                  })
                }
              </select>
              <div className='linechart'>
                <button style={{ display: newChartData && newChartData.length !== 0 ? "flex" : "none" }} className='dwn-btn' onClick={handleDownloadData}> <img src={dwn} /></button>
                {newChartData && <LineChartNew chartData={newChartData} timeScale={timeScale} />}
              </div>
            </>

          )}

        </section>
      </section>
    </>
  )

}

export default TransitEye;